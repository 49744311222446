<template  >

<div   class = " black_and_white ">
  

<br>
<br>
<br>
    
 

   
    
       <div class = "New_heading ">
  
        <b-row class = "test_header  "> 
  
               

  <b-card class  ="test_header   " 
 
  border = "none"
  title-color = "white"
  border-variant="white"
 
 fluid-grow
  
  >
 
  <h4 class = "new_header_mobile test_header" >Θέλουμε κοινότητες ελεύθερης έκφρασης και αυτοδιάθεσης των υποκειμένων να ξεπηδούν σε κάθε κοινωνικό χώρο, θέτοντας ένα πλαίσιο συλλογικοποίησης και αμοιβαίας συνύπαρξης που να μας υπενθυμίζει πως κανένα άτομο δεν είναι μόνο του απέναντι στην εκμετάλλευση και την καταπίεση που βιώνει. </h4>
<div class = "text-center">
<b-button  variant="outline-light"   class="text-center button_color" size = "sm"  href="/About"  >Λίγα λόγια για τη συνέλευση</b-button>

</div>

   </b-card>
 




</b-row>


  </div>
  <div class = "line_movement">
  <b-col cols = "2">


  </b-col>
  </div>
<br>
<b-container class ="container"  >

  <b-row >

    <b-col   >
    
      <p class = "general-heading__line_stories" ></p>
      <p class = "promos_stories  "> Κείμενα</p>
      
  <a   class  = "a_hover" href ="/one_year">
      <b-card   class= " card_body  "
         border-variant = "white"
 
    img-src= "../assets/menoume.png"    


    img-alt="Card Image"
    img-left
    img-height="100px;"
    text-variant= "dark"
   
    title.position ="5px"
  
   text-color = "black" 
   sub-title-text-variant="black"
   img-width="100px"
  no-body=""

  >
<div  class = "card_body_content">
  <h4 class = "feed_keimena ">Προσπαθώντας μια αποτίμηση ενός χρόνου πανδημίας </h4>

   </div> 
    
  </b-card>
  </a>
  <a   class  = "a_hover" href ="/Fascism">
      <b-card   class= " card_body  "
         border-variant = "white"
 
    img-src= "../assets/nonazis.png"    


    img-alt="Card Image"
    img-left
    img-height="100px;"
    text-variant= "dark"
   
    title.position ="5px"
  
   text-color = "black" 
   sub-title-text-variant="black"
   img-width="100px"
  no-body=""

  >
<div  class = "card_body_content">
  <h4 class = "feed_keimena ">Ο φασισμός πέθανε, ζήτω ο φασισμός</h4>

   </div> 
    
  </b-card>
  </a>
  <a   class  = "a_hover" href ="/No_name">
      <b-card   class= " card_body  "
         bg-variant=""
        border-variant="white"
    img-src= "../assets/no_borders.jpg"    


    img-alt="Card Image"
    img-left
    img-height="100px;"
    text-variant="dark"
    title.position ="5px"
  
   text-white ="color:white"
   sub-title-text-variant="black"
   img-width="100px"
  no-body=""

  >
<div  class = "card_body_content">
  <h4 class = "feed_keimena" >Γι αυτούς που δεν έχουν όνομα…</h4>
  
   </div> 
    
  </b-card>
  </a>
  <a   class  = "a_hover" href ="/Oxi">
      <b-card   class= " card_body  "
         bg-variant=""
    border-variant="white"
    img-src= "../assets/ethnic.jpg"    


    img-alt="Card Image"
    img-left
    img-height="100px;"
    text-variant="dark"
    title.position ="5px"
  
   
   sub-title-text-variant="black"
   img-width="100px"
  no-body=""

  >
<div  class = "card_body_content">
  <h4 class = "feed_keimena">5+1 σημεία γιατί η Μακεδονία δεν είναι μια και δεν είναι ελληνική</h4>
  
   </div> 
    
  </b-card>
  </a>
<!--
  <a   class  = "a_hover" href ="">
      <b-card   class= " card_body "
         bg-variant=""
 
    img-src= "../assets/no_borders.jpg"


    img-alt="Card Image"
    img-left
    img-height="100px;"
    text-variant="black"
   
    title.position ="5px"
  
   text-white ="color:white"
   sub-title-text-variant="black"
   img-width="100px"
  no-body=""

  >
<div  class = "card_body_content">
  <h4 >titlos</h4>
  <h6> ena mikro keimeno na mhn paramorfwnei </h6>
   </div> 
    
  </b-card>
  </a>
-->


  
 

<br>
<div class = "button_center" >

<b-button   href ="/posts" size="sm"  class = "button_color"  variant="outline-light"  >Περισσότερα</b-button>
</div>

<br>

    </b-col>

<b-col   class = "col-md-8 col-12 ">
      
      <div class = "vertical"></div>
      <p class = "promos_stories_feed_news   "> Δράσεις-Νέα
      
      </p>
     
 




<a class = "a_link_hover" href ="/Storm">
    <b-card  class= " font_header text-center img-hover-zoom "  style = "color:white"
    overlay
    img-src= "../assets/Acts/IMG_20201124_005755.jpg"    
   
    img-alt="Card Image"
    img-width ="100%;"
    img-height="420px;"
    border-variant="white"
   
    img-top
    
    title = " Τι ωραία καταιγίδα που έρχεται..." 
    
   align = ''
   
   
  

  >
    <b-card-text  > 
      
    </b-card-text>
  
  <b-button   href ="/Storm" size="sm"  class="button_color"  variant="outline-light"  >Περισσότερα</b-button>
 

    
  </b-card>
</a>

<br>
<b-card-group columns >
<a class = "a_link_hover" href ="/Network">
    <b-card  class= " font_header text-center img-hover-zoom "  style = "color:white"
    overlay
    img-src= "../assets/Acts/Pano_4.jpg"    
   
    img-alt="Card Image"
    
  img-width ="100%;"
  img-height="280px;"
    border-variant="white"
    no-body
    img-top
    
    title = "" 
    
   align = ''
   
   
  

  >



    
  </b-card>
</a>
<a class = "a_link_hover" href ="/Network">
    <b-card  class= " font_header text-center img-hover-zoom "  style = "color:white"
    overlay
    img-src= "../assets/Acts/pano_3.jpg"    
   
    img-alt="Card Image"
    
  img-width ="100%;"
  img-height="280px;"
    border-variant="white"
    no-body
    img-top
    
    title = "" 
    
   align = ''
   
   
  

  >



    
  </b-card>
</a>
<a class = "a_link_hover" href ="/Network">
    <b-card  class= " font_header text-center img-hover-zoom"  style = "color:white"
    overlay
    img-src= "../assets/Acts/pano_5.jpg"    
   
    img-alt="Card Image"
    
  img-width ="100%;"
  img-height="280px;"
    border-variant="white"
    no-body
    img-top
    
    title = "" 
    
   align = ''
   
   
  

  >



    
  </b-card>
</a>

</b-card-group>
  <br>


</b-col>


<br>

    
      

   
  
  </b-row>
</b-container>
 
</div>

</template>

<script>


//GraphQL Query for POSTS\]



  



export default {
  
  

}


</script>
<style  >
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Indie+Flower&display=swap');



.about_font {
font-size :40px;
font-family  : Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
letter-spacing: 3.5px;
line-height: 1.19;
color:black;

}

.black_and_white{

  background-color :white;
  max-width : 100%;
  
}

.New_heading {
  padding-left:15%;
  padding-right:15%;
  
  
  

}
.test_feed_header_1{
  font-family : "GeoBrandBold,CenturyGothic,AppleGothic,Gill Sans,Gill Sans MT,sans-serif";
}
.mt-heading-indented {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
}
.promos_stories {
    font-family: "Impact";
    font-size: 42px;
    letter-spacing: 1.5px;
    line-height: 1.19;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-transform: none;
    color: black;
}
.promos_stories_feed_news {
    font-family: "Impact";
    font-size: 42px;
    letter-spacing: 1.5px;
    line-height: 1.19;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-transform: none;
    color: black;
   text-align:right;
}
.a_hover {
  color:rgb(161, 106, 167) !important;
  font-size:32px; 
}  
.test_header {
    margin :0%;
   font-family: Arial, Roboto, sans-serif  !important;
    font-size: 20px;
    
   
    margin-bottom :10px;
    line-height: 1.6;
    color:  black;
    text-align: center;
    
  
    
    
   
    
 
}


.a_link_hover_2 {

  display:flex;
  max-width: 50%;
 
}
.background_color{
  background-color:none;
}
 

.general-heading__header {


    font-family: "GeoBrandBold,CenturyGothic,AppleGothic,Gill Sans,Gill Sans MT,sans-serif";
    font-size: 20px;
    letter-spacing: 3.5px;
    line-height: 1.19;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-transform: none;
}

.card_text {
    
    
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
    text-transform: uppercase;
    
    
    font-size:10px;
    -webkit-font-smoothing: antialiased;
    color: rgb(129, 120, 109);
    background-color: rgb(172, 84, 84);


    text-align: bottom;
}
.card_text_1 {
    font-family: 'Arial Narrow', Arial, sans-serif;
    text-transform: lowercase;
    margin-top :2;
    text-align-last: left;
    font-size: 20px;
    -webkit-font-smoothing: antialiased;
    color:rgb(255, 255, 255);
    background-color: black;
}

.button_center_heading {
  text-align:center
}
    

.bg {
  background-color :'white';
  background-image : '../assets/Test_1.jpg'
 
}
.bg_header {
  
 
 background-size:100% auto;
  background-color :rgb(0, 0, 0);
}
.general-heading__dek {
    font-family: Segoe UI, ;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 100;
   font-size: 8px;
    letter-spacing: 0;
    letter-spacing: .1px;
    font-size: 25px;
    line-height: 1.5;
    margin: 16px 0 23px;
    text-transform: initial;


}
.general-heading__line {
    background-color:rgb(161, 106, 167);
    height: 4px;
    line-height: 10px;
    margin: 1px auto 0;
    width: 100px;
    background :none;
    
}
.general-heading__line_stories_title_vertical {
    background-color:rgb(161, 106, 167);
    height: 120px;
    line-height: 10px;
    margin: 1px auto 0;
    width: 5px;
    position:relative;
   
    
   
    
   
    
}
.line_movement{
  align:left;
}
.general-heading__line_stories {
    background-color: rgb(161, 106, 167);
    height: 4px;
    line-height: 10px;
    margin: 0px ;
    width: 60px;
    
    
}

.general-heading__line_stories_title {
    background-color: rgb(161, 106, 167);
    height: 4px;
    line-height: 10px;
    margin:15px;
    width: 600px;
    
        
    
}
.general-heading__line_stories_title_feed_news {
    background-color: rgb(161, 106, 167);
    height: 4px;
    line-height: 10px;
    margin: 0px ;
    width:80px;
 

    
    
}
.general-heading__line_2 {
    background-color: rgb(255, 255, 255);
    height: 10px;
    
    margin: 21px auto 0;
    width: 300px;
}
.homepage_container__promos {
    padding-left:40px;
    flex: 0 0 auto;
    padding-right:40px;
   max-width : 60%;
    float: right;
}
.button_position {
  

}
.homepage_container__feed {
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 0;
   
        -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    margin-bottom: 50px;
    width: 100%;
    display: block;
    color:black;
    float: left;
}
.homepage_container__body {
 

    flex-direction: row;
        display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  padding :0;
    flex-direction: column-reverse;
    width: 100%;
    box-sizing: inherit;
    background-color:none;
    
    
   
    
}
    @media screen and (min-width: 720px)  {
        .mobile-break { display: none; }
        
        
        
    }
    @media screen and (max-width: 720px) {
        .button_center {
          text-align: center; 
          

    }
            .line {
          width:340px;
        }
    
    
    .card-title {
      font-size: 13px;
      text-align: center;

    
    
      
    }
        .new_header_mobile {
      font-size: 13px;
      text-align: center;

    
    
      
    }
    .image_height {
      
        height:320px;
        
      
    }
    
    }
.zoom {
  
   /* Animation */
  
  height: 200px;
  margin: 0 auto;
}
.card_body {
    margin :0%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 20pt;
    font-weight: 400;
    padding-bottom: 20px;
    line-height: 1.8;
    color:  rgb(161, 106, 167);
    text-align: left;
    text-decoration-color: rgb(255, 255, 255);
    
    
    background: none;
}

.card_body_content {
 
  font-family:;
    font-size: 2pt;
      -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-height: 20px;
    padding: 5px;
    padding-top: 0px;
    padding-bottom:23px;
    padding-left:25px;
    margin-bottom:3px;
    
    

}
.button_color {
  background-color: rgb(161, 106, 167);
  

}

.img-hover-zoom {
  /* [1.1] Set it as per your need */
  
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  text-emphasis: muted;
 
 
}
.text-muted {
  color :rgb(0, 0, 0);
}
/* [2] Transition property for smooth transformation of images */
.img-hover-zoom img {
    transition: transform 0.6s, filter 0.3s ;
  
  transform: scale(1.5.1)!important ;
}

/* [3] Finally, transforming the image when container gets hovered */
.img-hover-zoom:hover img {
  transform:scale(1.2) !important;
}
.background_image {
  background: url(../assets/test_1_purple.jpg);
  background-size: cover;
}
.container {
    padding-left:40px;
    flex: 0 0 auto;
    padding-right:40px; 
    max-width: flex;

   background-color:none;
  
}
.feed_keimena {
  font-size : 16px;
  font-family: GeoEditMedium,Franklin Gothic Medium,Franklin Gothic,ITC Franklin Gothic,Helvetica,Arial,sans-serif !important;
  font-weight :500;
}

.vertical {
      background: rgb(161, 106, 167);;
    content: "";
    display: block;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 5px;
    flex: 0 0 5px;


    position: relative;
        background-color: rgb(161, 106, 167);
        
    height: 50px;
    margin-top:2px;
    float:right;
    width: 4px;
    padding-left:5px;
    margin-left:5px;
}

</style>
