<template  >
<div id = "SinglePost" class="bg"  >
  <b-container>

    <b-col cols ="12">
 
  <b-card  class =  " bg image_height" 
   

   
   
   img-src = "../assets/kontrast_logo_black.png"
    img-alt="Card Image"
    img-height = "200px;"
    text-variant="dark"
    
    sub-title
   
    border-variant="white"

  >
            <h2 class = "header text-center "> 
    
				</h2>

           </b-card>
<p  class ="css-exrw3m mobile_text"> Η συνέλευση Kontrast δημιουργήθηκε από μια σειρά ατόμων διαφορετικών πολιτικών χώρων και πεποιθήσεων, με διαφορετικές κοινωνικές εμπειρίες και βιώματα. Κοινό στοιχείο αποτέλεσε η ανάγκη να δημιουργήσουμε μια συλλογικότητα έτσι ώστε να θέσουμε κάποιες σκέψεις και προβληματισμούς αναφορικά με την κοινωνική-πολιτική κατάσταση που βιώνουμε όλες και όλοι μας τα τελευταία χρόνια.</p>
<p  class ="css-exrw3m mobile_text">Αναγνωρίζουμε πως η ένταση της κρίσης του κράτους και του κεφαλαίου έχει εντείνει απ’ άκρη σ’ άκρη την κλίμακα ενός κοινωνικού πολέμου που διεξάγει αδιάκοπα το ελληνικό κράτος, όπως και κάθε άλλο κράτος παγκόσμια, απέναντι στους πληθυσμούς της επικράτειάς του. Περισσότεροι μπάτσοι, περισσότερος στρατός, εξοπλισμοί, πειθαρχημένη και εξαντλητική εργασία αποτελούν την αδιάκοπη πολιτική του. Βλέπουμε να συνεχίζεται αμείωτα ο πόλεμος εναντίων των μεταναστών, με πλήθος θυμάτων και φυλακισμένων και παράλληλα η ασυδοσία των αφεντικών απέναντι σε εργαζόμενους/ες, ντόπιες και μη, να αποτελεί πάγια τακτική. Περιστατικά έμφυλης βίας και καταπίεσης lgbtqi+ ατόμων αποτελούν καθημερινό φαινόμενο ενώ μη κανονικές ταυτότητες ωθούνται στο περιθώριο της κοινωνίας.  </p>
 <p  class ="css-exrw3m mobile_text"> Βέβαια, μέσα στο πλαίσιο αυτό της καταστολής, νέες ταυτότητες και νέα πολιτικά υποκείμενα, συλλογικά και μη, με το δικό τους ιδιαίτερο τρόπο αναδεικνύουν τις επιθυμίες τους απέναντι στις καταπιέσεις που βιώνουν καθημερινά. Αντίστοιχες φωνές αντίστασης και εστίες αγώνα είναι έτοιμες να ξεσπάσουν όσο η καταστολή και η καταπίεση των υποκειμένων βαθαίνει. Νέες εμπειρίες αγώνων αναδύονται συνεχώς θέτοντας σε μια διαδικασία επαναδιαπραγμάτευσης κάθε πολιτική και θεωρητική βάση που μέχρι σήμερα θεωρούσαμε δεδομένη. Εκείνο που μας κινητοποιεί είναι πως μπορούμε να αναδείξουμε αυτό το κοινό που μας συνδέει όλες και όλους, αυτό το κοινό που ξεσπάει σε κάθε στιγμή εξέγερσης και μας υπενθυμίζει πως εν τέλει η δυναμική του χώρου που μπορούμε να δημιουργήσουμε είναι τεράστια.  </p>
        <p  class ="css-exrw3m mobile_text"> Σαν συνέλευση Kontrast θέλουμε ακριβώς να αποτελέσουμε μια μικρή συμβολή στη δημιουργία ενός τέτοιου χώρου. Κατανοώντας πως η δημόσια σφαίρα κατακλύζεται καθημερινά από την καταστολή και τον κοινωνικό πόλεμο που διεξάγει το κράτος απέναντι σε πλήθος υποκειμένων, από την κυρίαρχη ιδεολογία του έθνους και την ανταγωνιστικότητα του κεφαλαίου, δεν μπορούμε παρά να δημιουργήσουμε τους δικούς μας αυτόνομους χώρους έκφρασης, συνύπαρξης και αγώνα. Από τη μεριά μας, δεν διαθέτουμε κάποιο έτοιμο, μεγάλο πολιτικό σχέδιο που θα πραγματώσει την παραπάνω επιδίωξη. Αντίθετα θεωρούμε πως η ανταλλαγή απόψεων και προβληματισμών, η επικοινωνία των μικρών και μεγάλων αγώνων και αντιστάσεων, από τους χώρους εργασίας, τις γειτονιές που ζούμε και κινούμαστε, θέτουν τις βάσεις προς μια τέτοια κατεύθυνση. Προσπαθούμε να ενισχύσουμε και να ανατροφοδοτήσουμε με κάθε μέσο αυτή την κίνηση.   </p>
        <h4 class = "css-exrw3m mobile_text" >Θέλουμε κοινότητες ελεύθερης έκφρασης και αυτοδιάθεσης των υποκειμένων να ξεπηδούν σε κάθε κοινωνικό χώρο, θέτοντας ένα πλαίσιο συλλογικοποίησης και αμοιβαίας συνύπαρξης που να μας υπενθυμίζει πως κανένα άτομο δεν είναι μόνο του απέναντι στην εκμετάλλευση και την καταπίεση που βιώνει. </h4>
        

    </b-col>
  

  </b-container>
</div>

</template>

<script>





export default {
  name: 'SinglePost',
  data(){
    return {
      mainProps: { blank: true,  width: 75, height: 75, class: 'm1' }
    }
  }
  
}

  

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped >
  body, html {
    height: 100%;
  }

  .header {
      font-size :50px;
  }
  .bg {
    padding-top  : 2%;
    /* The image used */
    background-color : white;

    /* Half height */
    height: 20%;
    margin: 2%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bob {
    height : 20%;

  }
  .css-exrw3m {
    
   
    font-family: nyt-imperial,georgia,'times new roman',times,serif;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    text-align:justify ;
    width: 100%;
  
  }
    @media screen and (min-width: 720px)  {
        .mobile-break { display: none; }
        
        
        
    }
    @media screen and (max-width: 720px) {
        .button_center {
          text-align: center; 
          
          

    }
            .line {
          width:340px;
        }
              .mobile_text {
    
   
    font-family: nyt-imperial,georgia,'times new roman',times,serif;
    font-size: 15px;
    line-height: 1.5625rem;
    text-align :justify;
    width: 100%;

  
  } 
    
    
    .card-title {
      font-size: 13px;
      text-align: center;

    
    
      
    }
        .new_header_mobile {
      font-size: 13px;
      text-align: center;

    
    
      
    }
        .image_height {
          padding-top :50px;
      
    }
    }
</style>
