<template  >
<div id = "SinglePost" class="bg"  >
  <b-container>

    <b-col cols ="12">
 
  <b-card  class =  "bg image_height" 
   

   
   
    img-src = "../assets/nonazis.png"
   
    img-alt="Card Image"
    img-height="400px;"
    text-variant="dark"
    
    sub-title
    
    border-variant="white"

  >
            <h2 class = "header text-center mobile_font "> Ο φασισμός πέθανε, ζήτω ο φασισμός
				</h2>
        <br>
 
         




    </b-card>   

         
<p  class ="css-exrw3m mobile_text"> Η δίκη της Χ.Α. έφτασε στο τέλος της και κάποια μέλη της οργάνωσης καταδικάστηκαν σε φυλάκιση. Όμως θεωρούμε το ζήτημα του φασισμού αρκετά πιο σύνθετο. Οι κρατικοί
θεσμοί δεν μπορούν να είναι σύμμαχοί μας στην καταπολέμηση και εξόντωση του φασιστικού τέρατος το οποίο και αποτελεί ένα όπλο στα χέρια τους κάθε φορά που απειλείται η καπιταλιστική κυριαρχία, λόγου χάρη σε συνθήκες οικονομικής κρίσης.
Ο ελληνικός φασισμός αποτελεί βασικό συστατικό στοιχείο της ταυτότητας του εθνικού υποκειμένου, δεν αφορά μόνο ακραίες νεοναζιστικές ομάδες αλλά την κυρίαρχη εθνική ιδεολογία που αναπαράγεται μέσα απ' τους ίδιους τους μηχανισμούς του κράτους (σχολείο, στρατός, εκκλησία κτλ), με την ενοποίηση γύρω από το φαντασιακό του έθνους/κράτους.
Έτσι νομιμοποιείται ιδεολογικά η δαιμονοποίηση του Άλλου, η επίθεση σε ό,τι δεν χωράει σε αυτό το αφήγημα, μετανάστ(ρι)ες, ρομά, ακόμα και lgbtqi+ άτομα, αφού δεν αναπαράγουν την
“ιερότητα” των οικογενειακών δεσμών.
</p>
<p  class ="css-exrw3m mobile_text "> </p>
 <p  class ="css-exrw3m mobile_text"> Πώς μπορούμε να λέμε και να νιώθουμε όμως, πως ο φασισμός νικήθηκε από το αστικό δικαστήριο αφού τόσες κοινωνικές ομάδες τον βιώνουν καθημερινά? Πολλοί μετανάστες και
μετανάστριες ζουν σε καθεστώς εξαίρεσης, κλεισμένοι και κλεισμένες σε στρατόπεδα συγκέντρωσης προσπαθώντας να επιβιώσουν σε αντίξοες συνθήκες, χωρίς ιατρική
περίθαλψη, χωρίς κάλυψη των βασικών τους αναγκών, χωρίς καν τρεχούμενο νερό. 
Οι επαναπροωθήσεις και οι πνιγμοί μεταναστών στο Αιγαίο, η ενίσχυση των συνόρων στο βόρειο τμήμα της χώρας είναι η πολιτική διαχείρισης του μεταναστευτικού από το κράτος.
Φασιστικές ενέργειες που όχι μόνο γίνονται πράξη από τους ίδιους τους κρατικούς μηχανισμούς αλλά είναι και κοινωνικά νομιμοποιημένες. Όμως το κράτος και οι φασιστοελληνάρες δεν προσπαθούν να κρύψουν το μίσος τους για τους μετανάστες.
Πογκρόμ από αστυνομικές ή ναζιστικές ομάδες κατά μεταναστών, αδιάκοποι αστυνομικοί έλεγχοι σε όποιον μοιάζει πρόσφυγας, στοχοποίηση ατόμων ως τζιχαντιστές είναι μερικές απ’ τις πιο καθημερινές και άμεσες εκφράσεις ρατσισμού που διακρίνονται στην ελληνική κοινωνία. Ο κάθε νοικοκύρης φτάνει ακόμα και να σκοτώσει τον εργάτη του που είναι μετανάστης για να μην τον πληρώσει, αφού μετά στα κοινωνικά δίκτυα οι υπόλοιποι νοικοκύρηδες τον ξεπλένουν.
 </p>
        <p  class ="css-exrw3m mobile_text"> Ακόμα, για την διασφάλιση της αναπαραγωγής των φιλελεύθερων ιδεών τους, δεν θα γινόταν να μην πολεμάνε το συνδικαλισμό και τους κοινωνικούς αγώνες. Φασιστικές
επιθέσεις σε πολιτικούς χώρους, εκκενώσεις καταλήψεων στέγασης και μη, συλλήψεις και επιβολή αντισυνταγματικών ποινών σε αγωνιστές ή ακόμα και σε μαθητές κατακλύουν το
ειδησεογραφικό χώρο. Οι πορείες μας καταστέλλονται από την αστυνομία με τους πιο βίαιους τρόπους, ενώ οποιαδήποτε γελοία φασιστική συγκέντρωση φρουρείται, προστατεύεται, ξεπλένεται. Η διαφορετικότητα μας χαλάει την συντηρητική ομοιογένεια που
αναζητούν. Τα queer άτομα καταπιέζονται, περιθωριοποιούνται, κατατρέχονται. Οι δολοφονίες τρανς ατόμων δεν έχουν τέλος. Αλλά πλέον δεν χρειάζεται να είσαι καν “παραβατικός” για να βιώσεις το μίσος τους. Η αστυνόμευση έχει ενισχυθεί ακραία. 
 </p>
         <p  class ="css-exrw3m mobile_text"> Οι
μπάτσοι είναι παντού. Μπουκάρουν σε σπίτια, συλλαμβάνουν τον κόσμο από τους δρόμους ή ακόμα και από τις αυλές τους, μοιράζουν πρόστιμα, ξύλο και φόβο.
Ο φασισμός δεν νικήθηκε. Ζει στους δρόμους μας και εκεί θα τον πολεμήσουμε.
Μένοντας όμως λίγο παραπάνω στην καταδίκη της Χρυσής Αυγής ως εγκληματική οργάνωση, φαίνεται πως το συναίσθημα της δικαίωσης δεν είναι και τόσο δυνατό. Δεν εννοούμε σε καμία περίπτωση πώς δεν μοιραστήκαμε και εμείς στην επιθυμία να
καταδικαστούν. Παρόλα αυτά δεν νιώθουμε και δεν περιμένουμε από το δικαστικό σύστημα να μας εκφράσει. Είναι οι ίδιοι που καταδικάζουν τους συντρόφους μας ως “εγκληματίες”,
με την ίδια δηλαδή ταμπέλα που καταδικάστηκαν τα μέλη της φασιστικής οργάνωσης. Γι΄ αυτό και δεν περιμένουμε από την αστική δικαιοσύνη τίποτα. Πρόκειται για έναν κρατικό θεσμό
που στην πραγματικότητα προασπίζει κυβερνητικά και εθνικιστικά συμφέροντα μόνο.

 </p>
         <p  class ="css-exrw3m mobile_text"> Επομένως, κανένα από τα εγκλήματα του κράτους που προαναφέρθηκαν (επαναπροωθήσεις, στρατόπεδα συγκέντρωσης ,κ.τ.λ.), δεν θα δικαστούν ποτέ.
Αξίζει να αναφέρουμε κλείνοντας, πως από το 2008 και μετά, ελέω καπιταλιστικής κρίσης, επανήλθε στη μόδα, όπως και κάθε τι ρετρό άλλωστε, η λεγόμενη θεωρία των 2 άκρων, ή
θεωρία των πετάλων, όπως είναι αλλιώς γνωστή. Μια θεωρία βγαλμένη από τα σπλάχνα του ψυχρού πολέμου, χωρίς καν την ύπαρξη σήμερα κάποιου “αντίπαλου δέους”. Εκτός από την επικίνδυνη εξίσωση κομμουνισμού- φασισμού, ταυτίζονται όλες οι φωνές αλλά και πραχτικές που μπορούν να χαρακτηριστούν ριζοσπαστικές, βίαιές, εχθρικές προς την παντοκρατορία της αστικής δημοκρατίας, η δημιουργία του λεγόμενου "εσωτερικού
εχθρού". Από τη μια οι καταλήψεις κτιρίων στον κόσμο της ατομικής ιδιοκτησίας, η έμπρακτη αλληλεγγύη των από τα κάτω, οι αγώνες κατά της εργασιακής ζούγκλας που βιώνουμε, και από την άλλη οι μαχαιριές σε μεταναστρ(ι)ες και αντιφασίστες, οι επιθέσεις σε Ρομά και lgbtqi+ άτομα, από μπράβους, τσιράκια των αφεντικών και κακομοιραίους, είναι περίπου το ίδιο πράγμα. Δεν έχουμε αυταπάτες, η ιστορία είναι μια διαρκής σύγκρουση
συμφερόντων, και εμείς ξέρουμε τα δικά μας. Όπως επίσης δεν τρέφουμε αυταπάτες για την συχνά, με το γάντι, αντιμετώπιση των φασιστών από το βαθύ κράτος και τη συχνή συνεργασία ή και κάλυψη τους από τους μπάτσους.


 </p>
<h2 class = "text-center">Η αστική δημοκρατία έχει νικήσει κι ο εσωτερικός εχθρός τελικά ήταν πάντα ένας</h2>


        
        
    </b-col>
  

  </b-container>
</div>

</template>

<script>





export default {
  name: 'Fascism',
  data(){
    return {
      mainProps: { blank: true,  width: 75, height: 75, class: 'm1' }
    }
  }
  
}

  

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  body, html {
    height: 100%;
  }

  .header {
      font-size :50px;
  }
  .bg {
    padding-top  : 2%;
    /* The image used */
    background-color : rgb(255, 255, 255);

    /* Half height */
    height: 20%;
    margin: 2%;

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bob {
    height : 20%;

  }
      .css-exrw3m {
    
   
    font-family: nyt-imperial,georgia,'times new roman',times,serif;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    text-align :justify;

    width: 100%;
  
  }

      @media screen and (min-width: 720px)  {
        .mobile-break { display: none; }
        
        
        
    }
    @media screen and (max-width: 720px) {
        .button_center {
          text-align: center; 
          

    }
      .mobile_text {
    
   
    font-family: nyt-imperial,georgia,'times new roman',times,serif;
    font-size: 15px;
    line-height: 1.5625rem;
    text-align :justify;
    width: 100%;
    

  
      }
      .mobile_font {

        font-size:30px;
      }
      .image_height {
        padding-top:60px;
      }
      }

</style>
