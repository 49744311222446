<template  >
<div  class="bg"  >
  <b-container  >

    <b-col cols ="12">
 
  <b-card  class =  "bg" 
   

   
   
   img-src = ""
    img-alt="Card Image"
    img-height="400px;"
    text-variant="dark"
    
    sub-title
    style = "max-width:  ;"
    border-variant="white"

  >
            <h2 class = "header text-center mobile_font  "> Προσπαθώντας μια αποτίμηση ενός χρόνου πανδημίας
				</h2>
        <br>
            <b-card-text  >
<p  class ="css-exrw3m mobile_text"> Ένας χρόνος έχει περάσει από το πρώτο κρούσμα του κορνοϊού στην Ελλάδα και τις δηλώσεις του υπουργού υγείας πως “δεν υπάρχει κανένας λόγος πανικού” και “πως είμαστε απόλυτα προετοιμασμένοι”. Στην πραγματικότητα βέβαια, αυτό που βιώνουμε είναι τελείως διαφορετικό. Χιλιάδες κρούσματα και θάνατοι, πάγωμα - ξεπάγωμα μεγάλου κομματιού της παραγωγής και της αγοράς, lockdown,  απαγορεύσεις και γενικότερα μέτρα “πρόληψης” και “περιορισμού” της νόσου που περιέχουν μια μεγάλη δόση μπατσοκρατίας, είναι η συνθήκη που αντιμετωπίζουμε.
</p>
<div>
<b-img fluid center src = "../assets/katharma.png"> </b-img>

</div>
<br> 
<h3 class = "text-center">Αντιμετώπιση πανδημίας: ατομική ευθύνη, καταστολή
</h3>
<br> 

<p  class ="css-exrw3m mobile_text">Το μεγάλο στοίχημα κάθε κράτους είναι να βγει μέσα από αυτή την κατάσταση ως ο καταλληλότερος -και μόνος ικανός εγγυητής την υγείας και της ασφάλειάς μας καθώς αυτό ως θεσμός -ανεξαρτήτως διαχειριστή- θα πρέπει να είναι ικανός να προστατεύει και να αναπαράγει τη ζωή των πολιτών του. Έτσι λοιπόν και το ελληνικό κράτος προτάσσοντας το λεγόμενο «γενικό συμφέρον» έχει λάβει σειρά μέτρων από την αρχή της πανδημίας τα οποία υπακούουν σε δύο βασικούς άξονες πολιτικής και ρητορικής:
<b class ="css-exrw3m mobile_text">τη κρατική καταστολή - περιστολή ατομικών ελευθεριών και την επίκληση της ατομικής ευθύνης.
 </b>
</p>



 <p  class ="css-exrw3m mobile_text"> Σε σχέση με τον πρώτο άξονα, είναι σαφές ότι η κρατική προσέγγιση αντιμετώπισης της  πανδημίας είχε ως αιχμή την αστυνομία. Ο περιορισμός της κυκλοφορίας και η απαγόρευση συγκεντρώσεων συμβαδίζουν με μια υπερενισχυμένη αστυνομία που επιτηρεί το πληθυσμό διαρκώς. Οι προσλήψεις μπάτσων και η αναβάθμιση του ρόλου τους, οι νέοι εξοπλισμοί αλλά και η περαιτέρω ανάπτυξη των μέσων παρακολούθησης, καταστολής και πειθάρχησης έρχονται σε αντιδιαστολή με την απαξίωση του ήδη υποβαθμισμένου συστήματος υγείας, το οποίο πλέον βρίσκεται σε οριακή κατάσταση. Εύκολα μπορούμε να καταλήξουμε στο συμπέρασμα πως η αύξηση της χρηματοδότησης του συστήματος υγείας, οι περισσότερες ΜΕΘ θα οδηγούσαν σε βελτιωμένη δυνατότητα αντιμετώπισης των κρουσμάτων και άρα λιγότερους θανάτους. Βέβαια αξίζει να σημειώσουμε πως όσο σοσιαλδημοκρατική και να γίνει η διαχείρισή της υγείας, θα παραμένει ένα εμπόρευμα, που θα ακολουθεί τον νόμο ζήτησης – προσφοράς.
 </p>

        <p  class ="css-exrw3m mobile_text"> Για το κράτος η συγκεκριμένη διαχείριση με διάφορα και κλιμακούμενα χαρακτηριστικά (απαγόρευση συναθροίσεων, επιβολή όλο και υψηλότερων προστίμων, περιορισμός και απαγόρευση μετακινήσεων, επιβολή απαγόρευσης κυκλοφορίας, κλείσιμο πάρκων, κ.λ.π.), αποτελεί μια πιο φθηνή εναλλακτική σε σχέση με την αύξηση δημοσιονομικών δαπανών.
 </p>

        <p  class ="css-exrw3m mobile_text"> Παράλληλα με τα παραπάνω έρχεται να προστεθεί και η επίκληση της ατομικής ευθύνης, σύμφωνα με την οποία ο καθένας/ η καθεμία μας προσωπικά ευθύνεται για την διάδοση του ιού και τους θανάτους. Με βάση αυτό το αφήγημα μας καλεί να αυτοπεριοριστούμε και να συμμορφωθούμε απόλυτα με τις επιταγές του, διαμορφώνοντας οξυμένη κοινωνική αντιπαράθεση σε σχέση με το ποιος είναι υπεύθυνος για την πανδημία. Ταυτόχρονα όμως προχωράει στην ψήφιση σειράς νομοσχεδίων και με την πρόφαση της προστασίας της δημόσιας υγείας απαγορεύει τις συναθροίσεις, καταστέλλει κάθε αντίδραση και χαρακτηρίζει αυτούς που αντιδρούν ως κίνδυνο για τη δημόσια υγεία και υπαίτιους για την κατάσταση.
 </p>



<p  class ="css-exrw3m mobile_text"> <b class = "css-exrw3m mobile_text"> Ένα χρόνο τώρα λοιπόν ζούμε μια διάχυση της παραβατικότητας. </b> Όλοι παραβιάζουμε εν δυνάμει το νόμο, και το κράτος πρέπει να προστατέψει την κοινωνία από “εμάς”. Με αυτή τη δικαιολογία είδαμε παραδείγματα ακραίας καταστολής σε πολλές περιπτώσεις, όπως σε κινητοποιήσεις έναντι στα διάφορα νομοσχέδια που έχει φροντίσει όλο αυτό το διάστημα να ψηφίσει η κυβέρνηση, στις φοιτητικές κινητοποιήσεις έναντι στο νόμο Κεραμέως-Χρυσοχοΐδη, στις κινητοποιήσεις στήριξης στον απεργό πείνας και δίψας Δ. Κουφοντίνα , στις πλατείες από την Κυψέλη μέχρι τη Νέα Σμύρνη. Αλλά σε αυτό τον κανόνα εντάσσονται και όλα τα περιστατικά αστυνομικής αυθαιρεσίας που έχουν βγει στη δημοσιότητα το τελευταίο έτος. 
<b class = "css-exrw3m mobile_text">Στην προσπάθειά του να κερδίσει κοινωνική συναίνεση για τις επιλογές του, το ελληνικό κράτος δεν έχει κανένα δισταγμό να συκοφαντήσει και να καταστείλει βίαια κάθε ενέργεια που αντιβαίνει στις επιταγές του και δεν χωράει στους 6 κωδικούς μετακίνησής.  </b>


</p>


<p  class ="css-exrw3m mobile_text"> Το πραγματικό ερώτημα παραμένει βέβαια πως γίνεται να μεταδίδεται ο ιός στα πάρκα αλλά όχι στα ΜΜΜ, πως να γίνεται να κολλάει αν βρεθούμε με φίλες/φίλους αλλά όχι αν δουλεύουμε σε κάποια αποθήκη ή σε κάποιο τηλεφωνικό κέντρο. Ή ακόμη καλύτερα πως νοιάζεται τόσο πολύ το κράτος για τη δημόσια υγεία μόνο όταν αφορά κάποια συγκέντρωση αλλά όχι όταν πρέπει να «διασφαλιστεί η επιβίωση της οικονομίας».
</p>


<p  class ="css-exrw3m mobile_text">Και μάλλον δεν είναι και πολύ δύσκολο να απαντηθούν αυτά. Η πανδημία είναι μια κρίση για το ελληνικό -και όχι μόνο- κράτος και το τελευταίο είναι έτοιμο να την εκμεταλλευτεί και να ανασυγκροτήσει τις παραγωγικές του σχέσεις. Τα νομοσχέδια που έχουν ψηφιστεί όλο αυτο το διάστημα αποτελούν μάρτυρα αυτης της κατάστασης. Απο το εκπαιδευτικά τα εργατικά τα συνδικαλιστικά και τα ζητήματα που αφορούν τις διαδηλώσεις, απο το περιβάλλον μεχρι τις επενδύσεις δεν υπάρχει τομέας που να μην εχουν περάσει νέες ρυθμίσεις που ενισχύουν τις κοινωνικές ανισότητες και σκληραίνουν το κρατικό μηχανισμό.
</p>
<br>

<b-img fluid center src = "../assets/amasito.jpg"> </b-img>


<br>


         <h3 style = "font-size : 1.75rem;" class = "text-center" > Πολιτικές έκτακτες ανάγκης, αόρατοι πληθυσμοί, θανατοπολιτική.

 </h3>
<br>
<p  class ="css-exrw3m mobile_text"> Η διεύρυνση της δράσης αυτής του κρατικού μηχανισμού που πλέον επιτηρεί και διαμεσολαβεί κάθε πλευρά της καθημερινότητάς μας περιλαμβάνει ακόμη και την ισχυροποίηση της κρατικής διάκρισης και διαχείρισης των πλεοναζόντων -για το ίδιο- πληθυσμών. Η διάδοση της έννοιας του ευάλωτου πληθυσμού δεν αφορά προτευόντως την υγεία κατηγοριών ανθρώπων αλλά την πρόσβαση τους στο υγειονομικό σύστημα. Και το κράτος μιλώντας για την ευαλωτότητα αυτή δεν μας αφήνει καμιά αμφιβολία ότι θα εξασφαλίσει την αδυναμία τους σε σχέση με τον υπόλοιπο πληθυσμό.  Μέσα στην πανδημία άστεγοι/ες, μετανάστ(ρι)ες, τοξικοεξαρτημένοι/ες, φυλακισμένοι/ες και έγκλειστοι/ες κλπ βιώνουν μια όλο και πιο σκληρή συνθήκη είτε με την ολική κοινωνική τους αποκοπή και απομόνωση είτε με τη στέρηση των αναγκαίων μέσων επιβίωσης. 
</p>



<p  class ="css-exrw3m mobile_text">Το “μένουμε σπίτι”, το κρατικό μότο στη διάρκεια της καραντίνας, προϋποθέτει κατ’ αρχάς σπίτι. Οι άστεγοι έτσι κι αλλιώς, δεν έχουν κάποιο σπίτι για να γυρίσουν σε αυτό, οι χρήστες ουσιών βρίσκονται σε πολύ δυσχερέστερη θέση σε σχέση με πριν, οι μετανάστ(ρι)ες συνεχίζουν να στοιβάζονται σε κλειστά κέντρα κράτησης χωρίς πρόσβαση σε περίθαλψη και στοιχειώδεις συνθήκες υγιεινής, οι έγκλειστοι σε ψυχιατρεία και φυλακές αντιμετωπίζουν μια ακόμα πιο διογκωμένη συνθήκη εγκλεισμού λόγω κατάργησης των επισκεπτηρίων κτλ. Αυτοί οι πληθυσμοί στερούνται την ταυτότητα του “πολίτη”, καθώς αποκλίνουν από το κανονιστικό κοινωνικό κρατικό πλαίσιο και έτσι απλά αποτελούν αριθμούς που το κράτος διαχειρίζεται, και πολλές φορές απλά δεν εμφανίζονται στις στατιστικές.  
</p>



<p  class ="css-exrw3m mobile_text">Η  κρατική ρητορική φαίνεται πως έχει ενσωματώσει ως οργανικό κομμάτι διακυβέρνησης την κατάσταση έκτακτης ανάγκης. Και εδώ και ένα χρόνο βρισκόμαστε σε έκτακτη ανάγκη και πιο συγκεκριμένα σε “πόλεμο με έναν αόρατο εχθρό”. Βέβαια αν γυρίσουμε λίγο πίσω και θυμηθούμε την κατάσταση πριν ο κορονοϊός φτάσει σ΄ εμάς, θα δούμε ότι
<b > βρισκόμασταν σε μια άλλη συνθήκη εξαίρεσης, άλλη μια κατάσταση έκτακτης ανάγκης, που το κράτος καλούσε τους πολίτες να συστρατευθούν ενάντια στον κοινό εχθρό, ο οποίος τότε δεν ήταν άλλος από τους/τις μετανάστ(ρι)ες. </b>
.  Στρατός, αστυνομία και έλληνες πατριώτες, αναλάμβαναν τη φύλαξη των συνόρων, πυροβολώντας, ψεκάζοντας, ξεγυμνώνοντας και κακοποιώντας αυτή την “ασύμμετρη απειλή που προσπαθούσε να εισβάλει και να αποσταθεροποιήσει τη χώρα”.

</p>


<p  class ="css-exrw3m mobile_text">Η κυριαρχία του κράτους ασκείται και μέσω του διαχωρισμού πολίτη και αλλοδαπού και ο καθορισμένος από τα σύνορα χώρος “οφείλει” να προστατευτεί υπέρ του εθνικού συμφέροντος και της εθνική ομοιογένειας μέσω των απελάσεων. Παράγεται έτσι ένας αποκλεισμένος από το δικαίωμα διαβίωσης εντός της επικράτειας πληθυσμός, ένας πληθυσμός χωρίς βασικά δικαιώματα. Η ζωή αυτών των ανθρώπων καταλήγει να είναι απλά ένα βιολογικό γεγονός, το οποίο συνεχώς αμφισβητείται και δοκιμάζεται.
</p>





<p  class ="css-exrw3m mobile_text"> Με αφετηρία αυτή την παρατήρηση, μοιάζει προφανές γιατί η πανδημία δεν μας πλήττει όλους το ίδιο. Σε μια έκτακτη ανάγκη οι ήδη παγιωμένοι εχθρικοί πληθυσμοί μοιάζουν να “απειλούν” περισσότερο και έτσι οι υφιστάμενες δομές εξουσίας (ρατσισμός, πατριαρχία, ταξικός αποκλεισμός) δημιουργούν βαθύτερες καταπιέσεις. Συνεπώς και η πάγια εδώ και χρόνια μεταναστευτική πολιτική του κράτους που συμπυκνώνεται στον εγκλεισμό των μεταναστ(ρι)ων σε  hotspot και τις απελάσεις, ενισχύθηκε εν μέσω της πανδημίας. Όπως και με τα ιδρύματα και τις φυλακές μιλάμε πλέον για τόπους όπου οι πληθυσμοί δεν αγγίζονται από ζητήματα υγείας. Η υγεία δεν είναι για αυτούς αλλά απειλείται από αυτούς.

</p>
<p  class ="css-exrw3m mobile_text"> Οι ήδη άθλιες συνθήκες διαβίωσης μέσα στα στρατόπεδα, η έλλειψη στέγασης, υγειονομικών εγκαταστάσεων θέρμανσης, αξιοπρεπούς φαγητού και συνθηκών διαβίωσης επιδεινώνονται συνεχώς. Στα στρατόπεδα συγκέντρωσης μεταναστ(ρι)ών αρκεί ένα μόνο κρούσμα για να κηρυχθεί το camp  σε καραντίνα, και τα περισσότερα είναι σε μόνιμο lockdown από την αρχή της πανδημίας. Από την αρχή της πανδημίας έχει καταργηθεί το δικαίωμα αίτησης ασύλου γεγονός το οποίο προχωρά παράλληλα με τα σχεδόν καθημερινά βίαια pushbacks μεταναστών στα σύνορα. Όλο και περισσότεροι μετανάστες μένουν άστεγοι με τις μαζικές εξώσεις που έχουν ξεκινήσει το τελευταίο διάστημα.

</p>
<p  class ="css-exrw3m mobile_text"> Επιπλέον, μέσα σε αυτή την κατάσταση,  το ελληνικό υπουργείο Μετανάστευσης και Ασύλου  ανακοίνωσε ότι το Πακιστάν και το Μπαγκλαντές θεωρούνται ασφαλείς χώρες και υπέβαλλε πρόταση στην Ευρωπαϊκή Επιτροπή, την Frontex και την Ευρωπαϊκή Συνοριοφυλακή, για την άμεση επιστροφή 1450 μεταναστών/στριών που δεν δικαιούνται διεθνούς προστασίας, βάσει της συμφωνίας Ελλάδας-Τουρκίας. Με αυτό τον τρόπο ανοίγεται ο δρόμος για νέες απελάσεις οι οποίες σε αρκετές περιπτώσεις αφορούν ανθρώπους που έχουν εκκρεμείς νομικές  υποθέσεις (και προθεσμίες για δικαστικές διεκδικήσεις). 

</p>
<p  class ="css-exrw3m mobile_text"> Και ενώ η πανδημία συνεχίζει να αποτελεί το καθημερινό μας πρόβλημα, τελευταία στοιχεία μας ενημερώνουν αυξάνονται στη χώρα μας οι θάνατοι συνολικά και για λόγους πέρα από τον κορονοϊό. Η έλλειψη επαρκούς χρηματοδότησης των νοσοκομείων έχει οδηγήσει σε μια κατάσταση όπου άνθρωποι με σοβαρά προβλήματα υγείας δεν μπορούνε να απευθυνθούν στο νοσοκομειακό σύστημα για βοήθεια καθώς αυτό μονοπωλείται από την διαχείριση του covid.
<br>
</p>
<b-img fluid center src = "../assets/menoume.png"> </b-img>
<br>
 <h3 style = "font-size : 1.75rem;" class = "text-center" >
Οι επιθυμίες και οι ανάγκες των καταπιεσμένων δεν χωράνε σε 6 κωδικούς.

 </h3>
 <br>
<p  class ="css-exrw3m mobile_text"> Δεν χρειάζεται μεγάλη φαντασία για να καταλάβει κανείς ότι η πανδημία δεν είναι απλά μια έκτακτη ανάγκη που σύντομα θα περάσει αλλά η αφορμή με βάση την οποία τα κράτη ανασυγκρότησαν τις κοινωνικές σχέσεις και αναπροσαρμόστηκαν. Πολλά από όσα ζούμε ήρθαν για να μείνουν. Μαζί με τις εξαιρέσεις της πανδημίας δημιουργείται και μια νέα κανονικότητα. Και ενώ φαίνεται ότι οι πανδημίες αποτελούν γεγονός του σύγχρονου καπιταλισμού και δεν αποτέλεσε ο covid κάποιο τυχαίο φαινόμενο - ίσως ούτε και κάποιο μεμονωμένο-, είναι αυτή η πραγματικότητα που πρέπει να μας κινητοποιήσει να σκεφτούμε πως θα διασφαλίσουμε την προστασία των κοινοτήτων μας στις νέες συνθήκες. Γιατί το γενικό συμφέρον και ο βασικός εκφραστής του, το κράτος, δεν μίλησε και δεν θα μιλήσει ποτέ για εμάς. Γιατί γνωρίζουμε ότι το γενικό συμφέρον ήταν και είναι το συμφέρον του κεφαλαίου. 

</p>
<p  class ="css-exrw3m mobile_text"> Μπορεί το επόμενο διάστημα οι περιορισμοί των μετακινήσεων, τα αλλεπάλληλα lockdown να παύσουν, η καθημερινότητα της διαβίωσης και των συνθηκών εργασίας μας όμως ακολουθούν ήδη εν μέσω πανδημίας τη νέα κανονικότητα που επιβάλλουν από κοινού κράτος και κεφάλαιο. Η καθημερινότητά μοιάζει με ένα ασφυκτικό καθεστώς επιτήρησης, μια αστυνομοκρατούμενη κατάσταση όπου παγιώθηκε τους τελευταίους μήνες. Αναδιαρθρώσεις λαμβάνουν χώρα σε κάθε τομέα αλλά εκπαίδευση και εργασία δείχνουν τις σημαντικότερες ενδείξεις των αλλαγών που έπονται. Το εκπαιδευτικό σύστημα αναδιαρθρώθηκε έτσι ώστε χιλιάδες παιδιά να μένουν εκτός πανεπιστημίων σχηματίζοντας τον μελλοντικό στρατό των ευέλικτων και ανειδίκευτων εργαζόμενων. Μέσα στην καραντίνα “εκπαιδευτήκαμε” να δουλεύουμε από το σπίτι μας, να συγχέουμε πλήρως τον ελεύθερο με τον εργάσιμο χρόνο μας σε συνθήκες εντατικοποίησης όπου το ωράριο για πολλές/πολλούς καταργήθηκε στην πράξη μέσω της επίκλησης των αφεντικών πως είμαστε σπίτι μας και με άπλετο χρόνο να διαθέσουμε για δουλειά. 

</p>
 <h5 style = "font-size : 1.75rem;" class = "text-center" >
Η αντίδραση σε αυτή την πραγματικότητα είναι που πλέον εκφράζεται ενεργά στους δρόμους. 

 </h5>


<b-img fluid center src = "../assets/road.jpg"> </b-img>

<br>
<p  class ="css-exrw3m mobile_text"> Το μούδιασμα του φόβου φεύγει και η ζωή των κινημάτων αναδύεται. Οι δεκάδες πορείες, διαμαρτυρίες των τελευταίων ημερών δεν εκφράζουν απλώς μια απάντηση στα γεγονότα της Νέας Σμύρνης. Υπάρχει κάτι πολύ βαθύτερο ήδη στις πρώτες αυτές φωνές αντίδρασης μετά την αρχή της πανδημίας. Υπάρχει κάτι που μας συνδέει απέναντι στην πραγματικότητα που βιώνουμε. Κάτι που χτίζει δίκτυα και κοινότητες αλληλεγγύης, μέσα από το οποίο ξεπηδούν συνελεύσεις γειτονιών, καταλήψεις, δράσεις και συντονισμοί· όπου δημιουργούνται χώροι ατομικής και πολιτικής έκφρασης, χώροι που η ζωή μας δεν ορίζεται από το κεφάλαιο και την αναπαραγωγή του αλλά τοποθετούνται εκτός του κύκλου δουλειά – σπίτι – δουλειά – ψώνια. Είναι αυτό  που διαμορφώνει τις επιθυμίες για απελευθέρωση από τη σχέση εκμετάλλευσης του κεφαλαίου και την εξουσία του κράτους.

</p>



 <h3 style = "font-size : 1.75rem;" class = "text-center" >
Είναι το κοινό της εξέγερσης που έρχεται. Και ναι εδώ ο φόβος αλλάζει στρατόπεδο.

 </h3>


        
         </b-card-text>




    </b-card>   
    </b-col>
  

  </b-container>
</div>

</template>

<script>





export default {
  name: 'one_year',
 

      metaInfo() {
    return {
      title: "Προσπαθώντας μια αποτίμηση ενός χρόνου πανδημίας | Kontrast",
      meta: [   {
        vmid: 'ogtitle',
        property:'og:title',
        content: 'Προσπαθώντας μια αποτίμηση ενός χρόνου πανδημίας'},

        {
        property:'og:image',
        content: '../assets/menoune.png'},
                {
        property:'og:url',
        content: 'https://kontrast.netlify.app/one_year'},
                        {
        property:'og:description',
        content: 'Προσπαθώντας μια αποτίμηση ενός χρόνου πανδημίας'}

      ]
    };
  }
};
    
  

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  body, html {
    height: 100%;
  }
  .container {
    max-width:auto;

  }

  .header {
      font-size :50px;
      padding-top: 60px;
  }
  .bg {
    padding-top  : 2%;
    /* The image used */
    background-color : rgb(255, 255, 255);

    /* Half height */
    height: 20%;
   

    /* Center and scale the image nicely */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bob {
    height : 20%;

  }
      .css-exrw3m {
    
   
    font-family: nyt-imperial,georgia,'times new roman',times,serif;
    font-size: 1.125rem;
    line-height: 1.5625rem;
    text-align :justify;

    width: 100%;
  
  }

      @media screen and (min-width: 720px)  {
        .mobile-break { display: none; }
        
        
        
    }
    @media screen and (max-width: 720px) {
        .button_center {
          text-align: center; 
          

    }
      .mobile_text {
    
   
    font-family: nyt-imperial,georgia,'times new roman',times,serif;
    font-size: 15px;
    line-height: 1.5625rem;
    text-align :justify;
    width: 100%;

  
  }
  .mobile_font {
    font-size: 30px;
  }
    .card-title {
      font-size: 13px;
      text-align: center;


    
    }
    .container {
      max-width :auto ;
      padding-right:1px;
      padding-left:1px;
      

    } 
    
    }
  
</style>
